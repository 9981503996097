import * as React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { StarknetConfig, InjectedConnector } from '@starknet-react/core'

export default function Layout({ children }) {

const connectors = [
  new InjectedConnector({ options: { id: 'braavos' }}),
  new InjectedConnector({ options: { id: 'argentX' }}),
]
  return (
    <StarknetConfig connectors={connectors}>
      <Header/>
      {children}
      <Footer/>
    </StarknetConfig>
  )
}